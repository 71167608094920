<template>
  <div class="app-container">
    <el-form :model="describeModel" >
      <vue-ueditor-wrap v-model="content"
                        :config="editorConfig"
                        :editorDependencies="['ueditor.config.js','ueditor.all.js']" />
    </el-form>
    <el-row style="width: 500px;margin: 10px auto;">
      <el-button style="margin-top:25px;width: 500px;" type="primary" @click="handleDialogConfirm()">提交</el-button>
    </el-row>
  </div>
</template>

<script>

import {detailDescribe, updateDescribe} from "@/api/describe";

const defaultDescribe = {
  id: null,
  content:""
};

export default {
  name: 'ApplicationDescribe',
  components: {},
  data() {
    return {
      describeModel: Object.assign({}, defaultDescribe),
      content : '',
      editorConfig : ({
        // 编辑器不自动被内容撑高
        autoHeightEnabled: true,
        // 不显示元素路径
        elementPathEnabled: false,
        // 初始容器高度
        initialFrameHeight: 500,
        // 最大字数限制
        maximumWords: 10000,
        // 编辑器z-index防遮挡, 特别是在elementUi的弹窗里显示时,这个值必须够大.
        zIndex: 1000,
        // 初始容器宽度
        initialFrameWidth: '100%',
        // 是否从服务器加载配置
        loadConfigFromServer: true,
        // 服务器加载配置的路径
        serverUrl: 'https://website/index/ueditor',

        UEDITOR_HOME_URL: '/UEditorPlus/',
        UEDITOR_CORS_URL: '/UEditorPlus/',
        // 工具栏配置
        toolbars: [
          [
            "fullscreen",   // 全屏
            "source",       // 源代码
            "|",
            "undo",         // 撤销
            "redo",         // 重做
            "|",
            "bold",         // 加粗
            "italic",       // 斜体
            "underline",    // 下划线
            "fontborder",   // 字符边框
            "strikethrough",// 删除线
            "removeformat", // 清除格式
            "formatmatch",  // 格式刷
            "|",
            "forecolor",    // 字体颜色
            "backcolor",    // 背景色
            "|",
            "rowspacingtop",// 段前距
            "rowspacingbottom",    // 段后距
            "lineheight",          // 行间距
            "|",
            "paragraph",           // 段落格式
            "fontfamily",          // 字体
            "fontsize",            // 字号
            "|",
            "indent",              // 首行缩进
            "|",
            "justifyleft",         // 居左对齐
            "justifycenter",       // 居中对齐
            "justifyright",
            "justifyjustify",      // 两端对齐
            "|",
            "horizontal",          // 分隔线
            "|",
            "print",               // 打印
            "preview",             // 预览
          ]
        ],
      }),


    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail(){
      detailDescribe(1).then(response=>{
        if (response.code === '0') {
          this.describeModel = response.data;
          this.$nextTick(() => {
            this.content = response.data.content;
          })
        }
      })
    },
    //提交操作
    handleDialogConfirm() {
      this.describeModel.content = this.content;
      updateDescribe(this.describeModel).then(res => {
        if (res.code === '0') {
          this.$message({
            message: '修改成功',
            type: 'success',
          });
        }else {
          this.$message({
            type: "error",
            message: res.msg
          })
        }
        this.dialogVisible =false;
        this.getDetail();
      });
    },


  }
}


</script>
